/***
 * FSA TypeScript Entry Point
 * @author Arthur Moore <ArthurMO@mohela.com>
 * @copyright MOHELA 2022
 ***/

import 'common/main';
import './app.scss';

console.log("FSA")
